import React, { useState, useEffect } from 'react';

const AnalyticalCard = ( { title, subtitle, quantity } ) => {
    return (
        <div className="bg-white rounded-lg overflow-hidden shadow-md p-6">
            <h2 className="text-lg font-bold mb-2">{ title }</h2>
            <h2 className="text-lg font-bold mb-2">{ subtitle }</h2>
            <p className="text-4xl text-gray-900 font-bold">{ quantity }</p>
        </div>
    );
};

const DashboardPage = () => {
    const [ dailyActiveUsers, setDailyActiveUsers ] = useState( 0 );
    const [ monthlyActiveUsers, setMonthlyActiveUsers ] = useState( 0 );
    const [ dailyNewUsers, setDailyNewUsers ] = useState( 0 );
    const [ monthlyNewUsers, setMonthlyNewUsers ] = useState( 0 );
    const [ averageSessionTime, setAverageSessionTime ] = useState( 0 );
    const [ appleStuff, setAppleStuff ] = useState( 0 );
    const [ dataStuff, setDataStuff ] = useState( 0 );
    const [ errorStuff, setErrorStuff ] = useState( 0 );

    useEffect( () => {
        const currentDate = new Date();                                             //Setting the current date.
        const dayStartDate = new Date( currentDate );                               //And subsequently setting the current date as the day start date.
        const monthStartDate = new Date( currentDate );                             //And subsequently setting the current date as the month start date.
    
        dayStartDate.setDate( currentDate.getDate() - 1 );                          //Then adjusting the day start date to a date 1 day ago.
        monthStartDate.setDate( currentDate.getDate() - 30 );                       //Then adjusting the month start date to a date 30 days ago.
        
        const endDate = currentDate;
        const dayStartDateString = dayStartDate.toISOString().slice( 0, 10 );
        const monthStartDateString = monthStartDate.toISOString().slice( 0, 10 );
        const endDateString = endDate.toISOString().slice( 0, 10 );
        
        // Fetching data from the server when this component mounts
        const fetchData = async () => {
            try {
                const dailyActiveUsersResponse = await fetch( `https://cyasoon.io/iOS/getActiveUsersByDate?startDate=${dayStartDateString}&endDate=${endDateString}` );
                const dailyActiveUsersData = await dailyActiveUsersResponse.json();
                setDailyActiveUsers( dailyActiveUsersData.length );

                const monthlyActiveUsersResponse = await fetch( `https://cyasoon.io/iOS/getActiveUsersByDate?startDate=${monthStartDateString}&endDate=${endDateString}` );
                const monthlyActiveUsersData = await monthlyActiveUsersResponse.json();
                setMonthlyActiveUsers( monthlyActiveUsersData.length );

                const dailyNewUsersResponse = await fetch( `https://cyasoon.io/iOS/getCreatedUsersByDate?startDate=${dayStartDateString}&endDate=${endDateString}` );
                const dailyNewUsersData = await dailyNewUsersResponse.json();
                setDailyNewUsers( dailyNewUsersData.length );

                const monthlyNewUsersResponse = await fetch( `https://cyasoon.io/iOS/getCreatedUsersByDate?startDate=${monthStartDateString}&endDate=${endDateString}` );
                const monthlyNewUsersData = await monthlyNewUsersResponse.json();
                setMonthlyNewUsers( monthlyNewUsersData.length );

                const averageTimeResponse = await fetch( 'https://cyasoon.io/iOS/getAverageTime' );
                const averageTimeData = await averageTimeResponse.json();
                let minutes = ( averageTimeData[ 0 ].totalAvgTime ) / 60;
                setAverageSessionTime( Math.floor( minutes ) );

                //Make App Store Connect API call by way of the server
                const appleResponse = await fetch( 'https://server.metrics.cyasoon.com/api/analytics' );
                const appleData = await appleResponse.json();

                setAppleStuff( appleData );
                setDataStuff( appleData );
                setErrorStuff( appleData );

                // Use token for App Store Connect API
                // await fetch( 'https://api.appstoreconnect.apple.com/v1/analytics/reports', {
                //         headers: {
                //             Authorization: `Bearer ${token}`
                //         }
                //     } 
                // )
                // .then( response => setAppleStuff( response.json() ) )
                // .then( data => setDataStuff( ( data.json() ) ) )
                // .catch( error => setErrorStuff( JSON.stringify( error ) ) );
            } catch( error ) {
                console.error( 'Error fetching data:', error );
            }
        };

        fetchData();
    }, [] );

    return (
        <div className="min-h-screen flex justify-center items-center">
            <div style={{ width: '900px' }} className="bg-white p-8 rounded shadow-md w-full">
                <img src="CyasoonMetricsLogo.png" alt="Cyasoon Metrics"></img>
                <br/>
                <h2 className="text-xl font-bold mb-4">Welcome to Cyasoon ANALytics</h2>
                 
                <div className="container mx-auto px-4 py-8">
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-6">
                        <AnalyticalCard 
                            title="Daily Active Users"
                            subtitle="(DAU)"
                            quantity={ dailyActiveUsers }
                        />
                        <AnalyticalCard 
                            title="Monthly Active Users"
                            subtitle="(MAU)"
                            quantity={ monthlyActiveUsers }
                        />
                        <AnalyticalCard 
                            title="Daily New Users"
                            subtitle="&nbsp;"
                            quantity={ dailyNewUsers }
                        />
                        <AnalyticalCard 
                            title="Monthly New Users"
                            subtitle="&nbsp;"
                            quantity={ monthlyNewUsers }
                        />
                        <AnalyticalCard 
                            title="Average Session Time"
                            subtitle="per User"
                            quantity={ averageSessionTime + ' min' }
                        />
                        <AnalyticalCard 
                            title="Apple"
                            subtitle="Stuff"
                            quantity={ appleStuff }
                        />

                        <AnalyticalCard 
                            title="Data"
                            subtitle="Stuff"
                            quantity={ dataStuff }
                        />

                        <AnalyticalCard 
                            title="Error"
                            subtitle="Stuff"
                            quantity={ errorStuff }
                        />
                    </div>
                </div>
                
            </div>
        </div>
    );
};

export default DashboardPage;