// App.js
import React, { useState, useEffect } from 'react';
import './App.css';

// Import PasswordPage and DashboardPage components
import PasswordPage from './PasswordPage';
import DashboardPage from './DashboardPage';

function App() {
  const [ isAuthenticated, setIsAuthenticated ] = useState( false );

  useEffect( () => {
    const storedAuth = localStorage.getItem( 'isAuthenticated' );
    if( storedAuth ) {
      setIsAuthenticated( true );
    }
  }, [] );

  return (
    <div className="App">
      { isAuthenticated ? (
        <DashboardPage  />
      ) : (
        <PasswordPage setIsAuthenticated={ setIsAuthenticated } />
      ) }
    </div>
  );
}

export default App;