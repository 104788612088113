// PasswordPage.js
import React, { useState } from 'react';

const PasswordPage = ( { setIsAuthenticated } ) => {
  const [ password, setPassword ] = useState( '' );

  const handleSubmit = ( e ) => {
    e.preventDefault();
    if( password === 'cyasoon2024' ) {
      setIsAuthenticated( true );
      localStorage.setItem( 'isAuthenticated', 'true' );
    } else {
      alert( 'Incorrect password. Please try again.' );
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="bg-white p-8 rounded shadow-md w-full sm:w-96">
        <img src="CyasoonMetricsLogo.png" alt="Cyasoon Metrics"></img>
        <br/>
        <h2 className="text-xl font-bold mb-4">Enter Your Password</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Password"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white rounded-md py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;
